<template>
  <div>
    <div v-if="showForm">
      <ValidationObserver v-slot="{ handleSubmit }">
        <b-form  @submit.prevent="handleSubmit(onSubmit)" >
        <b-row>
          <b-col>
            <!-- NAME -->
            <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
              <b-form-group
                  label="Name*:"
                  label-for="name-input"

              >
                <b-form-input
                    id="name-input"
                    name="name"
                    autocomplete="name"
                    v-model="address.name"
                    type="text"
                    placeholder="Name"
                    required
                ></b-form-input>
                <span class="text-danger">{{ errors[0] }}</span>
              </b-form-group>
            </ValidationProvider>
            <!-- Address Line 1 -->
            <ValidationProvider name="Address Line 1" rules="required" v-slot="{ errors }">
              <b-form-group
                  label="Address Line 1*:"
                  label-for="address1-input"
              >
                <b-form-input
                    id="address1-input"
                    name="addressLine1"
                    autocomplete="address-line1"
                    v-model="address.line1"
                    type="text"
                    placeholder="Address Line 1"
                    required
                ></b-form-input>
                <span class="text-danger">{{ errors[0] }}</span>
              </b-form-group>
            </ValidationProvider>
            <ValidationProvider name="Address Line 2" rules="" v-slot="{ errors }">
              <b-form-group
                  label="Address Line 2:"
                  label-for="address2-input"
              >
                <b-form-input
                    id="address2-input"
                    name="addressLine2"
                    autocomplete="address-line2"
                    v-model="address.line2"
                    type="text"
                    placeholder="Address Line 2"
                ></b-form-input>
                <span class="text-danger">{{ errors[0] }}</span>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>

        <b-row >
          <b-col>
            <ValidationProvider name="Postal Code" rules="required" v-slot="{ errors }">

              <b-form-group
                  label="Postal Code*:"
                  label-for="postalcode-input"
              >
                <b-form-input
                    id="postalcode-input"
                    name="addressPostalCode"
                    autocomplete="postal-code"
                    v-model="address.postalCode"
                    type="text"
                    placeholder="City"
                    required
                ></b-form-input>
                <span class="text-danger">{{ errors[0] }}</span>
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider name="State or Province" rules="" v-slot="{ errors }">
              <b-form-group
                  label="State or Province:"
                  label-for="admin1-input"
              >
                <b-form-input
                    id="admin1-input"
                    name="addressAdminArea1"
                    autocomplete="address-level1"
                    v-model="address.adminArea1"
                    type="text"
                    placeholder="State or Province"
                ></b-form-input>
                <span class="text-danger">{{ errors[0] }}</span>
              </b-form-group>

            </ValidationProvider>
          </b-col>
          <b-col>
            <ValidationProvider name="City" rules="required" v-slot="{ errors }">
              <b-form-group
                  label="City*:"
                  label-for="city-input"
              >
                <b-form-input
                    id="city-input"
                    name="addressAdminArea2"
                    autocomplete="address-level2"
                    v-model="address.adminArea2"
                    type="text"
                    placeholder="City"
                    required
                ></b-form-input>
                <span class="text-danger">{{ errors[0] }}</span>
              </b-form-group>
            </ValidationProvider>
            <CountrySelector v-if="showCountrySelector"></CountrySelector>
            <p v-else>Country:<br>{{ address.countryName }}</p>
          </b-col>
        </b-row>
        <b-button  type="submit" block variant="success">Validate Address</b-button>
      </b-form>
      </ValidationObserver>
    </div>
    <div v-else>
      {{address.name}}<br>
      {{address.line1}}<br>
      {{address.line2}}<br>
      {{address.adminArea1}}<br>
      {{address.postalCode}} {{address.adminArea2}}<br>
      {{address.countryName}}<br>
      <b-link @click="onChangeAddrClick">Change Address</b-link>
    </div>


  </div>
</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import CountrySelector from "@/components/CountrySelector";

export default {
  name: "AddressForm",
  props: {
    address: Object,
    showCountrySelector: Boolean
  },
  components:{CountrySelector, ValidationProvider,ValidationObserver},
  computed:{

  },
  data() {
    return {
      showForm: true
    }
  },
  methods: {
    onChangeAddrClick(){
      this.showForm = true
      this.$emit("on-change-address")
    },
    onSubmit() {
      this.showForm = false
      this.$emit("on-submitted")
    },
  }
}
</script>