var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showForm)?_c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('b-row',[_c('b-col',[_c('ValidationProvider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Name*:","label-for":"name-input"}},[_c('b-form-input',{attrs:{"id":"name-input","name":"name","autocomplete":"name","type":"text","placeholder":"Name","required":""},model:{value:(_vm.address.name),callback:function ($$v) {_vm.$set(_vm.address, "name", $$v)},expression:"address.name"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Address Line 1","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Address Line 1*:","label-for":"address1-input"}},[_c('b-form-input',{attrs:{"id":"address1-input","name":"addressLine1","autocomplete":"address-line1","type":"text","placeholder":"Address Line 1","required":""},model:{value:(_vm.address.line1),callback:function ($$v) {_vm.$set(_vm.address, "line1", $$v)},expression:"address.line1"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Address Line 2","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Address Line 2:","label-for":"address2-input"}},[_c('b-form-input',{attrs:{"id":"address2-input","name":"addressLine2","autocomplete":"address-line2","type":"text","placeholder":"Address Line 2"},model:{value:(_vm.address.line2),callback:function ($$v) {_vm.$set(_vm.address, "line2", $$v)},expression:"address.line2"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',[_c('ValidationProvider',{attrs:{"name":"Postal Code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Postal Code*:","label-for":"postalcode-input"}},[_c('b-form-input',{attrs:{"id":"postalcode-input","name":"addressPostalCode","autocomplete":"postal-code","type":"text","placeholder":"City","required":""},model:{value:(_vm.address.postalCode),callback:function ($$v) {_vm.$set(_vm.address, "postalCode", $$v)},expression:"address.postalCode"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"State or Province","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"State or Province:","label-for":"admin1-input"}},[_c('b-form-input',{attrs:{"id":"admin1-input","name":"addressAdminArea1","autocomplete":"address-level1","type":"text","placeholder":"State or Province"},model:{value:(_vm.address.adminArea1),callback:function ($$v) {_vm.$set(_vm.address, "adminArea1", $$v)},expression:"address.adminArea1"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',[_c('ValidationProvider',{attrs:{"name":"City","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"City*:","label-for":"city-input"}},[_c('b-form-input',{attrs:{"id":"city-input","name":"addressAdminArea2","autocomplete":"address-level2","type":"text","placeholder":"City","required":""},model:{value:(_vm.address.adminArea2),callback:function ($$v) {_vm.$set(_vm.address, "adminArea2", $$v)},expression:"address.adminArea2"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),(_vm.showCountrySelector)?_c('CountrySelector'):_c('p',[_vm._v("Country:"),_c('br'),_vm._v(_vm._s(_vm.address.countryName))])],1)],1),_c('b-button',{attrs:{"type":"submit","block":"","variant":"success"}},[_vm._v("Validate Address")])],1)]}}],null,false,3370274974)})],1):_c('div',[_vm._v(" "+_vm._s(_vm.address.name)),_c('br'),_vm._v(" "+_vm._s(_vm.address.line1)),_c('br'),_vm._v(" "+_vm._s(_vm.address.line2)),_c('br'),_vm._v(" "+_vm._s(_vm.address.adminArea1)),_c('br'),_vm._v(" "+_vm._s(_vm.address.postalCode)+" "+_vm._s(_vm.address.adminArea2)),_c('br'),_vm._v(" "+_vm._s(_vm.address.countryName)),_c('br'),_c('b-link',{on:{"click":_vm.onChangeAddrClick}},[_vm._v("Change Address")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }