<template>
  <div>
    <NavBar></NavBar>
    <div class="container">
      <Loader v-if="loading"></Loader>
      <div v-else>
        <b-row>
          <b-col cols="2">
            <b-button class="mb-3 mt-4" variant="info" :to="{name:routeNames.CART}"><i class="bi bi-arrow-left-circle"></i> Back</b-button>
          </b-col>
          <b-col>
            <SecuredByPaypal></SecuredByPaypal>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <hr>
          </b-col>
        </b-row>
        <div >
          <div class="row">
            <div class="col">
              <CartView :items="checkoutDetails.cart.items" v-on:cart-updated="loadCheckoutDetails" :updatable="false"></CartView>
              <b-row>
                <b-col md="2">
                </b-col>
                <b-col>
                  <b-row>
                    <b-col md="8">
                      <p><strong>Subtotal</strong></p>
                    </b-col>
                    <b-col>
                      <b-skeleton width="30%" v-if="loading"></b-skeleton>
                      {{ checkoutDetails.cart.totalPriceVATInc.display }}
                    </b-col>
                  </b-row>
                  <hr>
                </b-col>
              </b-row>
              <b-row class="text-secondary">
                <b-col md="2">
                </b-col>
                <b-col>
                  <b-row>
                    <b-col md="8">
                      <div v-if="checkoutDetails.cart.isAddressNeeded" >
                        Shipping to {{checkoutDetails.countryName}}
                      </div>
                      <div v-else>
                        Access to premium content online
                      </div>
                    </b-col>
                    <b-col>
                      <b-skeleton width="30%" v-if="loading"></b-skeleton>
                      {{ checkoutDetails.shipping.display }}
                    </b-col>
                  </b-row>
                  <hr>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="2">
                </b-col>
                <b-col>
                  <b-row>
                    <b-col md="8">
                      <p>Total Due</p>
                    </b-col>
                    <b-col>
                      {{ checkoutDetails.totalDue.display }}
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
            <div class="col">
              <div v-if="checkoutDetails.cart.isAddressNeeded">
                <h2>Shipping Address</h2>
                <AddressForm :address="this.address" :show-country-selector="false" v-on:on-submitted="displayPaypalPaymentButton" v-on:on-change-address="onChangeAddress" ></AddressForm>
              </div>
              <div v-if="paymentInProgress" class="text-center mt-2" >
                <b-spinner  variant="primary" label="Text Centered"></b-spinner> Payment in progress... Please wait...
              </div>
              <div v-if="paypalButtonLoading" class="text-center mt-2" >
                <b-spinner  variant="primary" label="Text Centered"></b-spinner> Loading Paypal Button... Please wait...
              </div>
              <br>
              <p v-if="paypalLoadedOnce && !paypalButtonLoading && showPaypalHelper" class="text-info"> <i class="bi bi-arrow-down"></i> Click on the Button to launch Payment</p>
              <div id="paypal-button-container"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import AuthHelper from "@/authHelper";
import Consts from "@/consts";
import Webservice from "@/webservice";
import Loader from "@/components/Loader";
import AddressForm from "@/components/AddressForm";
import SecuredByPaypal from "@/components/SecuredByPaypal";
import CartView from "@/views/CartView";
import ScriptLoader from "@/scriptLoader";

export default {
  name: "FunnelPay",
  components: {CartView, SecuredByPaypal, AddressForm, Loader, NavBar},
  created() {
    this.loading = true
    this.loadCheckoutDetails()
  },
  data(){
    return {
      currency: this.$route.query.currency,
      countryCode: this.$route.query.countryCode,
      loading: false,
      checkoutDetails: null,
      routeNames : Consts.ROUTE_NAMES,
      address: {
        firstname: "",
        lastname: "",
        line1: "",
        line2: "",
        adminArea1: "",
        adminArea2: "",
        postalCode: "",
        countryCode: this.$route.query.countryCode,
        countryName:"TEST"
      },
      showPaypalHelper: false,
      paypalButtonLoading: false,
      paypalLoadedOnce : false,
      paymentInProgress: false,
    }
  },
  methods: {
    loadCheckoutDetails(){
      this.loading = true
      window.scrollTo(0, 0);
      this.address.countryCode = this.countryCode
      AuthHelper.getOrCreateAnonUser().then((user) => {
        Webservice.getCheckoutDetails2(user.idToken,{
          currency : this.currency,
          countryCode : this.countryCode
        }).then((res)=>{
          this.checkoutDetails = res.data
          this.address.countryName = res.data.countryName
          if (! res.data.cart.isAddressNeeded) {
            // we do not need address,
            // case of digital goods
            this.displayPaypalPaymentButton()
          }
          this.loading = false
        }).catch((err)=>{
          console.error(err)
          this.makeToast("Error", "An unexpected error has occurred, please try again later", "danger")
        })
      }).catch((err)=>{
        console.error(err)
        this.makeToast("Error", "An unexpected error has occurred, please try again later", "danger")
      })
    },
    displayPaypalPaymentButton(){
      if(!this.paypalLoadedOnce){
        this.loadPaypalButton()
      } else {
        this.tooglePaypalButton()
      }
    },
    tooglePaypalButton(){
      let x = document.getElementById("paypal-button-container");
      if (x.style.display === "none") {
        this.showPaypalHelper = true
        x.style.display = "block";
      } else {
        this.showPaypalHelper = false
        x.style.display = "none";
      }
    },
    onChangeAddress(){
      this.tooglePaypalButton()
    },
    loadPaypalButton() {
      this.paypalButtonLoading = true
      AuthHelper.getOrCreateAnonUser().then((user) => {
          Webservice.tracking({
            action: {
              referrer: document.referrer,
              lang: navigator.language || navigator.userLanguage,
              name: "CLICK_PAYPAL_BUTTON",
              utm: {
                source: this.$route.query.utm_source,
                medium: this.$route.query.utm_medium,
                campaign: this.$route.query.utm_campaign,
                term: this.$route.query.utm_term,
                content: this.$route.query.utm_content,
              }
            }
          }, user.idToken)
        ScriptLoader.loadPaypal(this.currency).then(() => {
          this.paypalLoadedOnce = true
          this.showPaypalHelper = true
          window["paypal-"+this.currency].Buttons({
            createOrder: () => {
              this.paymentInProgress = true
              return Webservice.checkoutv2(user.idToken,"PAYPAL",this.countryCode,"EUR", this.address).then((res) => {
                return res.data.sessionId
              }).catch((err)=>{
                if (err.response.status === 400){
                  this.makeToast("Error", "The shipping address is not valid. Please double check it", "danger")
                } else {
                  this.makeToast("Error", "An unexpected error has occurred, please try again later", "danger")
                }
              })
            },
            onError:()=> {
              this.paymentInProgress = false
            },
            onCancel:()=> {
              this.paymentInProgress = false
            },
            onApprove: (data, actions) => {
              return actions.order.capture().then(() => {
                this.$router.push({name: Consts.ROUTE_NAMES.SUCCESS})
              });
            },
            style: {
              layout: 'horizontal',
              size: 'small',
              color: 'gold',
              shape: 'pill',
              label: 'checkout',
              tagline: 'true'
            }
          }).render('#paypal-button-container').then(() => {
            // button loaded
            this.paypalButtonLoading = false
          });
        }).catch(() => {
          this.makeToast("Error", "An unexpected error has occurred, please try again later", "danger")
        })
      }).catch(() => {
        this.makeToast("Error", "An unexpected error has occurred, please try again later", "danger")
      })
    },
    makeToast(title, message, variant = null) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        autoHideDelay: 5000,
        appendToast: true
      })
    },
  },
}
</script>

<style scoped>

</style>