<template>
  <!-- PayPal Logo --><table border="0" cellpadding="10" cellspacing="0" align="center"><tbody><tr><td align="center"></td></tr><tr><td align="center"><a href="https://www.paypal.com/fr/webapps/mpp/paypal-popup" title="PayPal Comment Ca Marche" onclick="javascript:window.open('https://www.paypal.com/fr/webapps/mpp/paypal-popup','WIPaypal','toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=1060, height=700'); return false;"><img src="https://www.paypalobjects.com/webstatic/mktg/logo-center/logo_paypal_securise_fr.png" border="0" alt="Securise par PayPal" /></a><div style="text-align:center"><a href="https://www.paypal.com/fr/webapps/mpp/why" target="_blank"><font size="2" face="Arial" color="#0079CD"><strong>PayPal Comment Ca Marche</strong></font></a></div></td></tr></tbody></table><!-- PayPal Logo -->
</template>

<script>
export default {
name: "SecuredByPaypal"
}
</script>

<style scoped>

</style>